function depot(DatatableLang, $state, $scope, WS, $window, $translate, $uibModal) {
    'ngInject';
    const vm = this;
    vm.depots = {};
    vm.autorize_change_sec = true;
    //vm.json_polygon_test = {"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":[[[2.834301,36.603815],[2.866402,36.603539],[2.901249,36.600783],[2.925797,36.601197],[2.923393,36.591825],[2.888374,36.58259],[2.8755,36.592928],[2.844257,36.594168],[2.813015,36.595408],[2.809925,36.602437],[2.828465,36.603677],[2.834301,36.603815]]]}};
    vm.json_polygon_test = { "type": "Feature", "properties": {}, "geometry": { "type": "Polygon", "coordinates": [[[2.834301, 36.603815], [2.866402, 36.603539], [2.901249, 36.600783], [2.925797, 36.601197], [2.923393, 36.591825], [2.888374, 36.58259], [2.887516, 36.594857], [2.860222, 36.596787], [2.843056, 36.586587], [2.845287, 36.595546], [2.813015, 36.595408], [2.809925, 36.602437], [2.828465, 36.603677], [2.834301, 36.603815]]] } };
    vm.get = function () {
        WS.get('depots/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.depots = response.data;
                    $scope.$apply();
                    $('.table_depot').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getOne = function () {
        WS.get('depots/' + $state.params.id)
            .then(function (response) {
                if (response.status == 200) {
                    vm.detail = response.data;
                    vm.detail.consigne = vm.detail.consigne.toString();
                    vm.detail.colisage = vm.detail.colisage.toString();
                    vm.detail.bloquage_commande = vm.detail.bloquage_commande.toString();
                    vm.detail.update_remise = vm.detail.update_remise.toString();
                    vm.detail.livraison = vm.detail.livraison.toString();
                    vm.detail.obligation_achat_avoir = vm.detail.obligation_achat_avoir.toString();
                    vm.detail.bloquage_list_client = vm.detail.bloquage_list_client.toString();
                    $scope.$apply();
                    vm.showMaps();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.update = function (depot) {
        WS.put('depots/' + depot.id, depot)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Camion_Prevendeur modifiée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.delete = function (id) {
        swal({
            title: $translate.instant('Depot'),
            text: $translate.instant("voulez vous vraiment supprimer ce Depot!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('depots/' + id).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Camion_Prevendeur supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('vous ne pouvez pas supprimer ce Camion_Prevendeur!'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }

    vm.ajouter = function (depot) {
        WS.post('depots', depot)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Camion_Prevendeur ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectedSousSocietes = function () {
        WS.get('distributeurs/selectedSousSocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.soussocietes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getPays = function () {
        WS.getScache('pays').then(function (response) {
            vm.getpays = response.data;
            vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
            console.log('from pays : ', vm.getpays)

            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.showMaps = function () {
        // console.log(vm.detail);
        // console.log(vm.detail.code);
        WS.getData('soussocietes/ByDepot', { depot_code: vm.detail.code })
            .then(function (response) {

                $('#container_maps').empty();
                $('#container_maps').append('<div style="width: 100%; height: 550px" id="mapContainerCmds"></div>');

                var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                var osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
                var osm = L.tileLayer(osmUrl, {
                    maxZoom: 18,
                    attribution: osmAttrib
                });
                //console.log('from maps : ', vm.getpays)
                var map = new L.Map('mapContainerCmds', {
                    center: new L.LatLng(vm.getpays.latitude, vm.getpays.longitude),
                    zoom: 8
                });

                osm.addTo(map)
                var geojsonMarkerOptions = {
                    radius: vm.detail.raduis,
                    //fillColor: "#ff7800",
                    //color: "#000",
                    // weight: 1,
                    // opacity: 0.1,
                    // fillOpacity: 0.6
                };
                console.log('vm.detail', vm.detail);
                var drawnItems = L.geoJson(JSON.parse(vm.detail.layer), {
                    // onEachFeature: function (feature, layer) {
                    //     console.log('drawnItems', drawnItems);
                    //     drawnItems.addLayer(layer);
                    //     layer.on('click', function (e) {
                    //         e.target.editing.enable();
                    //     });
                    // },
                    pointToLayer: function (feature, latlng) {
                        return L.circle(latlng, geojsonMarkerOptions);
                    }
                }).addTo(map);

                map.addControl(new L.Control.Draw({
                    edit: {
                        featureGroup: drawnItems
                    },
                    draw: {
                        polyline: true,
                        polygon: {
                            allowIntersection: false, // Restricts shapes to simple polygons
                            showArea: true,
                        },
                        rectangle: true,
                        marker: false,
                        circlemarker: false,
                        circle: false,
                    }
                }));

                map.on(L.Draw.Event.CREATED, function (e) {
                    var type = e.layerType
                        , layer = e.layer;
                    // layer.editing.enable();

                    drawnItems.addLayer(layer);
                    if (type == 'circle') {
                        var raduis = layer.getRadius()
                    } else {
                        var raduis = '';
                    }
                    var shape = layer.toGeoJSON()
                    var shape_for_db = JSON.stringify(shape);

                    var data = {
                        raduis: raduis,
                        type: type,
                        layer: shape_for_db,
                        depot_code: vm.detail.code
                    };

                    WS.post('depots/updateLayers', data)
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur crée avec succé',
                                    'success'
                                ).then(function () {
                                    $window.location.reload();
                                });;

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                    // layer.on("edit", function (event) {
                    //     var content = "";
                    //     if (type == "circle") {
                    //         content = 'Circle with : <ul><li> Radius : ' + layer.getRadius() + '</li>' +
                    //             '<li> Center : ' + layer.getLatLng() + ' </li></ul>';
                    //     }
                    //     else if (type === "polygon") {
                    //         content = ' Polygone with : ' + layer.getLatLngs()[0].length + ' nodes';
                    //     }
                    //     layer.bindPopup(content).openPopup();
                    // });
                });


                map.on(L.Draw.Event.EDITED, function (e) {
                    console.log('-------edited------------');
                    var layers = e.layers;
                    console.log(layers);
                    layers.eachLayer(function (layer) {
                        // Do whatever else you need to. (save to db; add to map etc)
                        // console.log('geometry: ', layer.toGeoJSON().geometry);
                        // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                        //remove previous layer
                        // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                        // console.log('area: ', area);
                        // map.addLayer(layer);

                        //issue: Finish drawing process with editable feature
                        var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                        //console.log('newLayer', newLayer);
                        //newLayer.editing.enable();
                        map.addLayer(newLayer);

                        var layer_type = layer.toGeoJSON().geometry.type;
                        if (layer_type == 'circle') {
                            var raduis = layer.getRadius()
                        } else {
                            var raduis = '';
                        }
                        var shape = newLayer.toGeoJSON()
                        var shape_for_db = JSON.stringify(shape);
                        //console.log(layer_type, shape_for_db);
                        var data = {
                            raduis: raduis,
                            type: layer_type,
                            layer: shape_for_db,
                            depot_code: vm.detail.code
                        };
                        //console.log(data);
                        WS.post('depots/updateLayers', data)
                            .then(function (response) {
                                if (response.data.success == true) {

                                    swal(
                                        'Succès',
                                        'Secteur crée avec succé',
                                        'success'
                                    ).then(function () {
                                        $window.location.reload();
                                    });
                                    $scope.$apply();

                                } else if (response.data.success == false) {
                                    swal('Error', 'Oups ', 'error');
                                }
                            })
                            .then(null, function (error) {
                                console.log(error);
                            });
                    });
                });

                //draw:deleted	

                map.on(L.Draw.Event.DELETED, function () {

                    WS.post('depots/updateLayers', { depot_code: vm.detail.code })
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur supprimé avec succé',
                                    'success'
                                ).then(function () {
                                    $window.location.reload();
                                });;

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                });

                $scope.$apply();
                /*setTimeout(function() {
                    mymap.invalidateSize();
                }, 80);*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.changeSequence = () => {
        vm.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'VerificationOfPassword',
            size: 'md',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.message_password = $translate.instant('confirmer le mot de passe');

                $scope.changeSequence = function () {
                    if ($scope.set_password != '' && $scope.set_password != undefined) {
                        var data = {
                            password: $scope.set_password
                        };
                        console.log(data);
                        WS.post('users/checkControlePassword', data).then(
                            function (response) {
                                if (response.data.success) {
                                    swal(
                                        $translate.instant('Succès'),
                                        $translate.instant('vous pouvez maintenant changer les séquences'),
                                        'success'
                                    ).then(function () {
                                        vm.modalInstance.close();
                                        vm.autorize_change_sec = false;
                                        vm.detail.modifiable=1;
                                        $scope.$apply();
                                    }).catch(function () {
                                        vm.modalInstance.close();
                                        vm.autorize_change_sec = false;
                                        vm.detail.modifiable=1;
                                        $scope.$apply();
                                    });;
                                } else {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('Mot de passe invalide'),
                                        'warning'
                                    );
                                }
                            },
                            function () {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant('Mot de passe invalide'),
                                    'warning'
                                );
                            }
                        );
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('Mot de passe Obligatoire'),
                            'warning'
                        );
                    }
                }
            }
        });
    }



}
export default {
    name: 'depot',
    fn: depot
};