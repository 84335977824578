function noteController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.regions = {};

    vm.getAllNotesEnvoye = function() {
        WS.getScache('notes/getAllNotesEnvoye')
            .then(function(response) {
                vm.notes_envoyes = response.data;
                $scope.$apply();
                $('.table-notes_envoye').DataTable({
                    "language": DatatableLang,
                    "paging": true,
                    "searching": true,
                    "order": []
                });
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getAllNotesRecu = function() {
        WS.getScache('notes/getAllNotesRecu')
            .then(function(response) {
                vm.notes_recu = response.data;
                $scope.$apply();
                $('.table-notes_recu').DataTable({
                    "language": DatatableLang,
                    "paging": true,
                    "searching": true,
                    "order": []
                });
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.playAudio = function(path) {
        console.log(path);
        var audio = new Audio(path);
        audio.play();
    };

    vm.getTrace = function() {
        WS.get('traces/coresspondance')
            .then(function(response) {
                if (response.status == 200) {
                    vm.traces = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.getOne = function() {
        WS.getScache('notes/getOne/' + $state.params.id)
            .then(function(response) {
                vm.note = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.changeEtat = function(etat) {
        WS.put('notes/changeEtat/' + $state.params.id, { etat: etat })
            .then(function(response) {
                console.log(response.data);
                swal(
                    'Succès',
                    response.data.message,
                    'success'
                );
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.update = function(region) {
        WS.put('regions/' + region.id, region)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Region modifiée avec Succès',
                        'success'
                    )
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette Note!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('notes/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Note Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette Note',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    };

    vm.storeNote = function(note) {
        WS.post('notes/storeNote', note)
            .then(function(response) {
                /*swal(
                  'Succé',
                  'note ajoutée avec succées',
                  'success'
                ).then(function(){
                    $window.location.reload();
                });*/
                $window.location.reload();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.depots = function() {
        WS.get('depots/selectDepot')
            .then(function(response) {
                vm.depots = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.clients = function() {
        WS.get('clients/selectClients')
            .then(function(response) {
                vm.clients = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getCommercialByDepot = function(code, liste) {

        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['depot_code'] == code) {
                return liste[i];
            }
        }
    }
}
export default {
    name: 'noteController',
    fn: noteController
};