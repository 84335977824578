function integrationerpController(AppSettings,$localStorage, $state, $scope, WS, $compile, $parse,Flash,$window) {
    'ngInject';
    const vm = this;
    $scope.moveCSV =  function(element) {
        var reader = new FileReader();
    
            reader.onload = function(event) {
                vm.csvfile.filetxt = event.target.result;
                vm.files = element.files;
            }
            //reader.readAsText(vm.files[0], 'Windows-1256');

        reader.readAsDataURL(element.files[0]);
       $scope.$apply();
    };

    /**
    * Wavesoft
    */

    vm.waveSoft_bl_avoir = function(){
        var url='erp/getByDateExport/wavesoft/bl';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.waveSoft_commande = function(){
        var url='erp/getByDateExport/wavesoft/commande';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.wavesoft_reglement = function(){
        var url='erp/getByDateExport/wavesoft_reglement';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.wavesoft_mouvement = function(){
        var url='erp/getByDateExport/wavesoft_mouvement';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.importWavesoftMouvement = function(){
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('erp/importWavesoftMouvement' , vm.csvfile , "POST")
            .then(function(response){
                if (response.data.success==true) {
                    swal(
                      'Succès',
                      response.data.message,
                      'Succès'
                    ).then(function(){
                      $window.location.reload();
                    });  
                } else {
                    swal(
                      'Warning',
                      response.data.message,
                      'warning'
                    ); 
                }
           },function(error){
                console.log(error);
           });
    };

    vm.importWavesoftClients = function(){
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('erp/importWavesoftClients' , vm.csvfile , "POST")
            .then(function(response){
                if (response.data.success==true) {
                    swal(
                      'Succès',
                      response.data.message,
                      'success'
                    ).then(function(){
                      $window.location.reload();
                    });  
                } else {
                    swal(
                      'Warning',
                      response.data.message,
                      'warning'
                    ); 
                }
                if(response.data.error==true){
                    swal(
                      'Warning',
                      '',
                      'warning'
                    ); 
                }
           },function(error){
                console.log(error);
           });
    };
    
    vm.exportWavesoftClients = function(){
        $window.location.href = AppSettings.apiUrl + 'erp/exportWavesoftClients';
    }

    /**
    * foxpro
    */

    vm.foxpro_exportExcelEntete_bl = function(){
        var url='erp/foxpro/foxpro_entete_bl';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.foxpro_exportExcelEntete_bl_details = function(){
        var url='erp/foxpro/foxpro_entete_bl_details';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.foxpro_exportExcelDetailReg = function(){
        var url='erp/foxpro/foxpro_exportExcelDetailReg';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.foxpro_exportExcelChargements = function(){
        var url='erp/foxpro/foxpro_exportExcelChargement';
        $window.location.href = AppSettings.apiUrl + url;

    };

    vm.foxpro_importExcelMouvement = function(){
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('erp/foxpro/foxpro_importExcelMouvement' , vm.csvfile , "POST")
            .then(function(response){
                if (response.data.success==true) {
                    swal(
                      'Succès',
                      response.data.message,
                      'Succès'
                    ).then(function(){
                      $window.location.reload();
                    });  
                } else {
                    swal(
                      'Warning',
                      response.data.message,
                      'warning'
                    ); 
                }
           },function(error){
                console.log(error);
           });
    };


    /**
     * Sage
     */

    vm.SageExportCommande = function(){
        var url='erp/sage/SageExportCommande';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.SageExportBlAvoir = function(){
        var url='erp/sage/SageExportBlAvoir';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.SageExportBlAvoirTGT = function(){
        var url='erp/sage/SageExportBlAvoirTGT';
        $window.location.href = AppSettings.apiUrl + url;

    };
    vm.SageExportCommandeSATEM = function(){
        var url='erp/sage/SageExportCommandeSATEM';
        $window.location.href = AppSettings.apiUrl + url;

    };

    vm.SageExportCommandeWinox = function(){
        var url='erp/sage/SageExportCommandeWinox';
        $window.location.href = AppSettings.apiUrl + url;

    };

    vm.SageExportClients = function(){
        var url='erp/sage/SageExportClients';
        $window.location.href = AppSettings.apiUrl + url;

    };

    vm.SageExportBlAvoirNatilait = function(type){
        var url='erpnatilait/ExportCommandes/'+type;
        $window.location.href = AppSettings.apiUrl + url;
    }

     vm.SageExportEnteteRMS = function(){

        var url='erprms/exportentete/'+vm.rms.date_debut +'/'+vm.rms.date_fin;
        $window.location.href = AppSettings.apiUrl + url;
    }
      vm.SageExportLigneRMS = function(){
        var url='erprms/exportlignes/'+vm.rms.date_debut +'/'+vm.rms.date_fin;
        $window.location.href = AppSettings.apiUrl + url;
    }

     vm.SageExportMouvementRMS = function(type){
        var url='erprms/exportmouvements/'+vm.rms.date_debut +'/'+vm.rms.date_fin;;
        $window.location.href = AppSettings.apiUrl + url;
    }

     vm.SageExportClientsRMS = function(type){
        var url='erprms/exportclients/'+vm.rms.date_debut +'/'+vm.rms.date_fin;;
        $window.location.href = AppSettings.apiUrl + url;
    }

     vm.SageExportReglementRMS = function(type){
        var url='erprms/exportReglementRms/'+vm.rms.date_debut +'/'+vm.rms.date_fin;;
        $window.location.href = AppSettings.apiUrl + url;
    }

    vm.getFirstSociete = function() {
        WS.get('societes/getFirstSociete').then(
            function(response) {
                vm.societe = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log('error : ->', error);
            }
        );
    };




    /**
     * Navision export
     */
    vm.navisionExportVente = function(type){
        var url='navisionErp/exportVente/'+type;
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.navisionExportClients = function(){
        var url='navisionErp/exportClients';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.navisionExportMouvements = function(){
        var url='navisionErp/exportMouvements';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.navisionExportProduits = function(){
        var url='navisionErp/exportProduits';
        $window.location.href = AppSettings.apiUrl + url;
    };
    
    vm.exportMouvementsProduit = function(){
        var url='navisionErp/exportMouvementsProduit';
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.exportDemandeChargement = function(){
        var url='erp/sage/SageDemandeChargement';
        $window.location.href = AppSettings.apiUrl + url;
    };
    vm.navisionExportReglements = function(){
        var url='navisionErp/navisionExportReglements';
        $window.location.href = AppSettings.apiUrl + url;
    };


    

}
export default {
    name: 'integrationerpController',
    fn: integrationerpController
};
